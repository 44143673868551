import React, { useEffect, useRef, useState } from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen-kwTracker-intro@xxl.png";
import { isSavedUserInfo } from "../helpers";
import PG_RD_ADVANTAGE_CONTENT from "../components/report-deals/Advantage/index.content";
// import { PAGES } from "../helpers/constants";
import RootProvider from "../store/providers/ReduxProvider";
import HomeIntro from "../components/report-deals/HomeIntro";
import HowWeDoIt from "../components/report-deals/HowWeDoIt";
import Work from "../components/report-deals/Work";
import Advantage from "../components/report-deals/Advantage";
import Possibility from "../components/report-deals/Possibility";
import CustomersSay from "../components/report-deals/CustomersSay";
import FPContainer from "../containers/report-deals/FPContainer";
import DealsRDModalWindow from "../components/report-deals/DealsDR/DealsRDModalWindow";
// import DealsRD from "../components/report-deals/DealsDR";
import Pricing from "../components/report-deals/Pricing";
import InfoContainer from "../containers/report-deals/InfoContainer";
import Guarantee from "../components/report-deals/Guarantee";
// import DealsBannerContainer from "../containers/common/DealsBannerContainer";
// import EmptySpace from "../components/report-deals/EmptySpace";
import SampleReport from "../components/report-deals/SampleReport";
import "../components/report-deals/index.scss";

const PgRD = () => {
  const { points } = PG_RD_ADVANTAGE_CONTENT;
  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isShowDealsContainer, setIsShowDealsContainer] = useState(true);
  const [isOpenedDealsModal, setIsOpenedDealsModal] = useState(false);
  const [isShowEmptySpace, setIsShowEmptySpace] = useState(true);

  const pricingRef = useRef(null);
  const sampleReportRef = useRef(null);

  const closeDealsModal = () => setIsOpenedDealsModal(false);
  const openDealsModal = () => {
    setIsOpenedDealsModal(true);
    setIsShowDealsContainer(false);
    localStorage.setItem(process.env.DEALS_RD_VERSION, "closed");
  };

  const scrollPricing = () => {
    !!pricingRef &&
      !!pricingRef.current &&
      pricingRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  useEffect(() => {
    setIsShowEmptySpace(!sessionStorage.getItem(process.env.DEALS_RD_VERSION));
    setIsSecondStateCollectEmail(isSavedUserInfo());
    setIsLoading(false);
  }, []);

  return (
    <RootProvider>
      <Layout customClass="PgRD" isSimpleTopPanel isDrPanel>
        <Seo
          title="Get New Ready-to-Go Products to Sell - Individual Research"
          description="Get a unique list of products to sell to suit the individual needs of your business. Save time by ordering product research based on the analysis of millions of products by AMZScout AI technology and Amazon experts."
          page="report-deals"
          manifest="browserconfig.xml"
          ogImg={OgImg}
        />
        <HomeIntro
          handlerClick={scrollPricing}
          sampleReportRef={sampleReportRef}
        />
        <Possibility />
        <HowWeDoIt />
        <SampleReport
          openDealsModal={openDealsModal}
          isSecondStateCollectEmail={isSecondStateCollectEmail}
          setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
          isLoading={isLoading}
          sampleReportRef={sampleReportRef}
        />
        <Work />
        <CustomersSay
          handlerClick={scrollPricing}
          sampleReportRef={sampleReportRef}
        />
        <Advantage points={points} />
        <Pricing
          pricingRef={pricingRef}
          setShowInfo={setShowInfo}
          sampleReportRef={sampleReportRef}
        />
        <FPContainer isLoading={isLoading} setIsLoading={setIsLoading} />
        <InfoContainer show={showInfo} setShowInfo={setShowInfo} />
        <Guarantee />
        <DealsRDModalWindow
          isOpened={isOpenedDealsModal}
          handlerClick={closeDealsModal}
        />
        {/* логика для плашки */}
        {/* <EmptySpace*/}
        {/*  isShow={isShowEmptySpace}*/}
        {/*  dealsSection={process.env.DEALS_RD_SECTION}*/}
        {/* />*/}
        {/* {isShowDealsContainer && (*/}
        {/*  <DealsBannerContainer*/}
        {/*    dealsUrl="/"*/}
        {/*    dealsVersion={process.env.DEALS_RD_VERSION}*/}
        {/*    dealsSection={process.env.DEALS_RD_SECTION}*/}
        {/*    transferSuccess={openDealsModal}*/}
        {/*    disableRedirect*/}
        {/*    label="Get Holiday Deals!"*/}
        {/*    category="Index"*/}
        {/*    setIsShowEmptySpace={setIsShowEmptySpace}*/}
        {/*  >*/}
        {/*    <DealsRD*/}
        {/*      isLoading={isLoading}*/}
        {/*      isSecondStateCollectEmail={isSecondStateCollectEmail}*/}
        {/*      setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}*/}
        {/*      isShowDealsContainer={isShowDealsContainer}*/}
        {/*      redirect={false}*/}
        {/*      title="Get a Sample Products Report"*/}
        {/*      subTitle="to Help Reach 5K/Mo for Your Business"*/}
        {/*      placeholder="Enter Your Email"*/}
        {/*      btnText="Get the Sample Report"*/}
        {/*      page={PAGES.REPORT_DEALS}*/}
        {/*    />*/}
        {/*  </DealsBannerContainer>*/}
        {/* )}*/}
      </Layout>
    </RootProvider>
  );
};

export default PgRD;
